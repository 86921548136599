import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts/sk";
import { compose } from "recompose";
import "./index.css";
import { Container, Row, Col } from "reactstrap";
import "react-animated-slider/build/horizontal.css";
import s2 from "../static/images/plan-s2.png";
import s3 from "../static/images/plan-s3.png";
import s4 from "../static/images/plan-s4.png";
import s5 from "../static/images/plan-s5.jpg";
import s6 from "../static/images/plan-s6.png";
import s7 from "../static/images/plan-s7.jpg";
import SEO from "../components/seo.js";
import SmoothScrollLink from "../components/smoothScrollLink";

const IndexPage = compose()((props) => (
  <Layout location={props.location}>
    <div>
      <Container>
        <Row>
          <Col md={12} className="features-beg pt-4 pb-0">
            <h1>Architektúra</h1>
            <p className="text-center">
              Pôvodný Urbanisticko-architektonický koncept riešeného územia a
              komplexné projekčné riešenie zabezpečilo Architektonické štúdio
              Atrium, s.r.o. z Košíc, držiteľ viacerých významných ocenení za
              dizajn a architektúru a jeden z najväčších spracovateľov územných
              plánov na Slovensku.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="architecture-nav sticky-top mt-4 mb-4">
        <ul>
          <li>
            <SmoothScrollLink to="S2">S2</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="S3">S3</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="S4">S4</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="S5">S5</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="S6+S7">S6</SmoothScrollLink>
            </li>
          <li>
            <SmoothScrollLink to="S6+S7">S7</SmoothScrollLink>
          </li>
          {/* <li>
            <SmoothScrollLink to="S7">S7</SmoothScrollLink>
          </li> */}
        </ul>
      </Container>
      <Container className="col-utilization" id="S2">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Areál S2</h2>
                <p className="mb-2">
                  <strong>Opis:</strong> logisticko-obchodný areál {" "}
                </p>
                <p className="mb-2">
                  <strong>Príjazd:</strong> z juhozápadu po účelovej komunikácii
                  {" "}
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> S2A: predané | S2B: postavené
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infraštruktúra:
                </h4>
                <ul>
                  <li>areál je dopravne zokruhovaný</li>
                  <li>cesty sú dimenzované na prepravu kamiónov</li>
                  <li>chodníky vedú od areálu k budúcej zastávke autobusu</li>
                  <li>parkoviská sa nachádzajú okolo hlavných budovs</li>
                  <li>tovar je dotovaný cez zákl. portály – doky</li>
                </ul>

                <h4 className="text-left strong mb-3">Okolie:</h4>
                <p>
                  <strong>na severe: </strong> biokoridor Balka (je navrhovaný na zachovanie a rozvoj)

                  <br />
                  <strong>na východe: </strong>obec Nová Polhora <br />
                  <strong>na juhu: </strong>kruhová križovatka a cesta 3325
                  <br />
                  <strong>na západe: </strong> futbalové ihrisko
                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s2} mb={3} />
                <h4 className="text-left strong mb-3">
                 Areál S2 (64 188 m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Opis:</strong> stavba pozostáva z dvoch logistických hál
                </p>
                <p>
                  <strong>Konštrukčné riešenie stavieb: </strong> viacloďové, viacmodulové
                </p>
                <p>
                  <strong>Použitý materiál:</strong>oceľ a železobetón 
                </p>
              </Col>
              <Col md={12} className="pt-4">
                <p>
                V septembri 2019 bol celý areál S2 predaný spoločnosti P3 Žilina s.r.o. <br />
                V januári tohto roku bola skolaudovaná prvá logistická hala o výmere 12 800 m<sup>2</sup>.<br />
                V apríli až máji roku 2020 začne výstavba druhej logistickej haly o výmere 12 800 m<sup>2</sup> <br />
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="col-utilization" id="S3">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Areál S3</h2>
                <p className="mb-2">
                  <strong>Opis:</strong> obchodno-obslužný areál, obsahujúci skladové haly, obchodné, obslužné a administratívne prevádzky a nevyhnutný rozsah dopravných plôch a zelene

                </p>
                <p className="mb-2">
                  <strong>Príjazd:</strong>  zo zbernej komunikácie 

                </p>
                <p className="mb-5">
                  <strong>Status:</strong> Rezervované pre Potraviny
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infraštruktúra:
                </h4>
                <ul>
                  <li>areál je dopravne zokruhovaný</li>
                  <li>cesty sú dimenzované na prepravu kamiónov</li>
                  <li>chodníky vedú od obslužno-administratívneho centra k budúcej zastávke autobusu</li>
                  <li>parkoviská sa nachádzajú na sever od prístupovej cesty</li>
                  <li>tovar je dotovaný cez zákl. portály – doky</li>
                </ul>

                <h4 className="text-left strong mb-3">Okolie:</h4>
                <p>
                značná časť zelene je navrhovaná v rozsahu OP vodovodu Starina
                  <br />
                  západnou časťou areálu prechádza vodovod Starina <br />
                  areál bude ležať medzi diaľnicou D1 a zbernou komunikáciou

                  <br />
                  <strong>na severe:</strong> diaľničná križovatka s diaľničných privádzačom Nová Polhora <br />
                  <strong>na juhu: </strong>  melioračný kanál 
                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s3} mb={3} />
                <h4 className="text-left strong mb-3">
                 Areál S3 (64 032 m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Opis:</strong>  stavebný objekt bude tvoriť jeden celok 
                </p>
                <p>
                  <strong>Konštrukčné riešenie stavieb: </strong>  viacloďové, viacmodulové
                </p>
                <p>
                  <strong>Použitý materiál:</strong> oceľ a železobetón
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="col-utilization" id="S4">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Areál S4</h2>
                <p className="mb-2">
                  <strong>Opis:</strong> logistický areál, obsahujúci skladové haly a administratívne prevádzky a nevyhnutný rozsah dopravných plôch a zelene 
                </p>
                <p className="mb-2">
                  <strong>Príjazd:</strong>  dva hlavné prístupy do areálu sú zo zbernej komunikácie 

                  {" "}
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> Rezervované
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infraštruktúra:
                </h4>
                <ul>
                  <li>areál je dopravne zokruhovaný</li>
                  <li>cesty sú dimenzované na prepravu kamiónov</li>
                  <li>chodníky vedú od obslužno-administratívneho centra k budúcej zastávke autobusu </li>
                  <li>parkovisko pre kamióny sa nachádza medzi príjazdom pre osobné autá, pozdĺž vnútorného obslužného okruhu </li>
                  <li>tovar je dotovaný cez zákl. portály – doky
</li>
                </ul>

                <h4 className="text-left strong mb-3">Okolie:</h4>
                <p>
                značná časť zelene je navrhovaná pred uličnou fasádou <br/>
                  <strong>na severe: </strong> cesta 3325 a kruhová križovatka


                  <br />
                  <strong>na východe: </strong>obec Nová Polhora (oddelená izolačnou zeleňou)
                  <br />
                  <strong>na západe: </strong>zberná komunikácia
                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s4} mb={3} />
                <h4 className="text-left strong mb-3">
                 Areál S4 (118 031 m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Opis:</strong>   stavebný objekt bude tvoriť jeden celok 
                </p>
                <p>
                  <strong>Konštrukčné riešenie stavieb: </strong> viacloďové, viacmodulové
                </p>
                <p>
                  <strong>Použitý materiál:</strong> oceľ a železobetón 
                </p>
              </Col>
              <Col md={12} className="pt-4">
                <p>
                <strong>Areál bol predaný spoločnosti LABAŠ s.r.o. na výstavbu skladových priestorov.</strong>

                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="col-utilization" id="S5">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Areál S5</h2>
                <p className="mb-2">
                  <strong>Opis:</strong>  logistický areál, obsahujúci 2 skladové haly a administratívnu prevádzku a nevyhnutný rozsah dopravných plôch a zelene

                </p>
                <p className="mb-2">
                  <strong>Príjazd:</strong> hlavný prístup do areálu z účelovej komunikácie 
                  {" "}
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> Prvý pozemok na  halu predaný | Druhý pozemok na halu rezervovaný
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infraštruktúra:
                </h4>
                <ul>
                  <li>areál je dopravne zokruhovaný</li>
                  <li>cesty sú dimenzované na prepravu kamiónov</li>
                  <li>chodníky vedú od obslužno-administratívneho centra k budúcej zastávke autobusu</li>
                  <li>parkovisko pre osobné autá sa nachádza pri vjazde a výjazde z areálu 
</li>
                  <li>tovar je dotovaný cez zákl. portály – doky
</li>
                </ul>

                <h4 className="text-left strong mb-3">Okolie:</h4>
                <p>
                mznačná časť zelene je navrhovaná na východnom a severnom okraji areálu <br/>
                na severnom okraji je trasované podzemné vedenie VN a trafostanicou <br/>

                  <strong>na severe: </strong>Areál S4 
                  <br />
                  <strong>na východe: </strong>obec Nová Polhora (oddelená izolačnou zeleňou)
 <br />
                  <strong>na juhu: </strong>obslužná komunikácia

                  <br />
                  <strong>na západe: </strong>ČOV

                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s5} mb={3} />
                <h4 className="text-left strong mb-3">
                 Areál S5 (27 702 m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Opis:</strong> stavba pozostáva z 2 budov 
                </p>
                <p>
                  <strong>Konštrukčné riešenie stavieb: </strong> viacloďové, viacmodulové
                </p>
                <p>
                  <strong>Použitý materiál:</strong> oceľ a železobetón 
                </p>
              </Col>
              <Col md={12} className="pt-4">
                <p>
                <strong>Prvá časť areálu na západnej strane pozemku je predaná spoločnosti Nerezové materiály s.r.o. na výstavbu skladových priestorov.</strong>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="col-utilization" id="S6+S7">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Areál S6 + S7</h2>
                <p className="mb-2">
                  <strong>Opis:</strong> logistický areál, obsahuje halu STK a administratívnu prevádzku a nevyhnutný rozsah dopravných plôch a zelene. Parcela S7A bola predaná VEDOS s.r.o. .  <br/> 
Tento areál (119 944 m2) bude rozdelený na dve lavice pozemkov (o výmere S6 cca 66 426  m2, a S7 cca 50 672 m2 ) určených na odkúpenie a vlastnú výstavbu



                </p>
                <p className="mb-2">
                  <strong>Príjazd:</strong> hlavný prístup do areálu z účelovej komunikácie
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> 2 Predané | 3 Voľné
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infraštruktúra:
                </h4>
                <ul>
                  <li>cesty sú dimenzované na prepravu kamiónov</li>
                  <li>chodníky vedú od administratívneho centra a skladov k budúcej zastávke autobusu</li>
                  <li>parkovisko pre osobné autá na severnom okraji, pri vyššej obslužnej komunikácii</li>
                </ul>

                <h4 className="text-left strong mb-3">Okolie:</h4>
                <p>
                značná časť zelene je navrhovaná na východnom a západnom okraji areálu<br/>
                  <strong>na severe: </strong>obslužná komunikácia  
                  <br />
                  <strong>na východe: </strong>obslužná komunikácia <br />
                  <strong>na juhu: </strong> Areál S7
                  <br />
                  <strong>na západe: </strong> zberná komunikácia

                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s7} mb={3} />
              </Col>
              <Col md={12} className="pt-4">
                <p>
                Na prvom pozemku je už zrealizovaná a skolaudovaná stavba STK pre osobné a nákladné automobily. 
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <Container className="col-utilization" id="S7">
        <Row className="content-utilization pb-0">
          <Col md={12}>
            <Row className="col-utilization pt-2 architecture">
              <Col md={7} sm={12}>
                <h2 className="text-left mb-4">Areál S7</h2>
                <p className="mb-2">
                  <strong>Opis:</strong> logisticko – výrobný areál, zahrňujúci skladové a výrobné haly , administratívnu prevádzku a nevyhnutný rozsah dopravných plôch a zelene 

                </p>
                <p className="mb-2">
                  <strong>Príjazd:</strong> hlavný prístup do areálu zo zbernej komunikácie 

                  {" "}
                </p>
                <p className="mb-5">
                  <strong>Status:</strong> S7A:  Voľné | S7B:  Voľné
                </p>

                <h4 className="text-left strong mb-3 mq-sm-n">
                  Infraštruktúra:
                </h4>
                <ul>
                  <li>areál je dopravne zokruhovaný</li>
                  <li>cesty sú dimenzované na prepravu kamiónov</li>
                  <li>chodníky vedú od dokov k budúcej zastávke autobusu </li>
                  <li>parkoviská pre osobné autá zamestnancov budú na západnom okraji, pri zbernej komunikácií </li>
                  <li>dotácia skladov a výroby bude zo severu a z juhu</li>
                </ul>

                <h4 className="text-left strong mb-3">Okolie:</h4>
                <p>
                značná časť zelene je navrhovaná na východnom a západnom okraji areálu<br/>
                na severnom okraji je trasované podzemné vedenie VN a trafostanicou

                  <strong>na severe: </strong> Areál S6
                  <br />
                  <strong>na východe: </strong>cesta 3325 
 <br />
                  <strong>na juhu: </strong>zberná komunikácia
                  <br />
                  <strong>na západe: </strong>zberná komunikácia
                </p>
              </Col>
              <Col md={5} sm={12}>
                <img src={s7} mb={3} />
                <h4 className="text-left strong mb-3">
                 Areál S7 (87 981m<sup>2</sup>)
                </h4>
                <p>
                  <strong>Konštrukčné riešenie stavieb: </strong> viacloďové, viacmodulové
                </p>
                <p>
                  <strong>Použitý materiál:</strong>oceľ a železobetón 
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container> */}

    </div>
    <SEO title="Architektúra" />
  </Layout>
));

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
